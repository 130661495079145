import React from "react";
import PropTypes from "prop-types";
import { Field } from "@mds/common/components/forms/form";
import { MINE_PARTY_APPOINTMENT_DOCUMENTS } from "@mds/common/constants/API";
import FileUpload from "@/components/common/FileUpload";
import { DOCUMENT, EXCEL } from "@/constants/fileTypes";

const propTypes = {
  onFileLoad: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  mineGuid: PropTypes.string.isRequired,
};

export const PartyRelationshipFileUpload = (props) => (
  <Field
    id="fileUpload"
    name="fileUpload"
    component={FileUpload}
    uploadUrl={MINE_PARTY_APPOINTMENT_DOCUMENTS(props.mineGuid)}
    acceptedFileTypesMap={{ ...DOCUMENT, ...EXCEL }}
    onFileLoad={props.onFileLoad}
    onRemoveFile={props.onRemoveFile}
    allowRevert
    allowMultiple
  />
);

PartyRelationshipFileUpload.propTypes = propTypes;

export default PartyRelationshipFileUpload;
