import { notification } from "antd";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { INoticeOfDeparture, ICreateNoD, INodDocumentPayload } from "@mds/common/interfaces";
import { ENVIRONMENT } from "@mds/common/constants/environment";
import { IDispatchError, error, request, success } from "../actions/genericActions";
import {
  NetworkReducerTypes
} from "@mds/common/constants/networkReducerTypes";
import CustomAxios from "../customAxios";
import {
  NOTICE_OF_DEPARTURE,
  NOTICES_OF_DEPARTURE,
  NOTICES_OF_DEPARTURE_DOCUMENT,
  NOTICES_OF_DEPARTURE_DOCUMENTS,
} from "@mds/common/constants/API";
import { createRequestHeader } from "../utils/RequestHeaders";
import {
  storeNoticeOfDeparture,
  storeNoticesOfDeparture,
} from "../actions/noticeOfDepartureActions";
import { AxiosResponse } from "axios";
import { AppThunk } from "@mds/common/interfaces/appThunk.type";

export const createNoticeOfDeparture = (
  payload: Partial<ICreateNoD>
): AppThunk<Promise<AxiosResponse<INoticeOfDeparture>>> => (
  dispatch
): Promise<AxiosResponse<INoticeOfDeparture>> => {
    dispatch(request(NetworkReducerTypes.CREATE_NOTICE_OF_DEPARTURE));
    dispatch(showLoading("modal"));

    return CustomAxios()
      .post(`${ENVIRONMENT.apiUrl}${NOTICES_OF_DEPARTURE()}`, payload, createRequestHeader())
      .then((response: AxiosResponse<INoticeOfDeparture>) => {
        notification.success({
          message: "Successfully created Notice of Departure.",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.CREATE_NOTICE_OF_DEPARTURE));
        return response;
      })
      .catch(() => {
        dispatch(error(NetworkReducerTypes.CREATE_NOTICE_OF_DEPARTURE));
      })
      .finally(() => {
        dispatch(hideLoading("modal"));
      });
  };

export const fetchNoticesOfDeparture = (
  mine_guid
): AppThunk<Promise<AxiosResponse<INoticeOfDeparture> | IDispatchError>> => (dispatch) => {
  dispatch(request(NetworkReducerTypes.GET_NOTICES_OF_DEPARTURE));
  dispatch(showLoading());
  const headers = {
    ...createRequestHeader(),
    params: {
      mine_guid,
    },
  };
  return CustomAxios()
    .get(`${ENVIRONMENT.apiUrl}${NOTICES_OF_DEPARTURE()}`, headers)
    .then((response) => {
      dispatch(success(NetworkReducerTypes.GET_NOTICES_OF_DEPARTURE));
      dispatch(storeNoticesOfDeparture(response.data));
      return response;
    })
    .catch(() => dispatch(error(NetworkReducerTypes.GET_NOTICES_OF_DEPARTURE)))
    .finally(() => dispatch(hideLoading()));
};

export const updateNoticeOfDeparture = (
  { nodGuid },
  payload
): AppThunk<Promise<AxiosResponse<INoticeOfDeparture>>> => (
  dispatch
): Promise<AxiosResponse<INoticeOfDeparture>> => {
    dispatch(request(NetworkReducerTypes.UPDATE_NOTICE_OF_DEPARTURE));
    dispatch(showLoading("modal"));
    return CustomAxios()
      .patch(`${ENVIRONMENT.apiUrl}${NOTICE_OF_DEPARTURE(nodGuid)}`, payload, createRequestHeader())
      .then((response) => {
        notification.success({
          message: "Successfully updated Notice of Departure.",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.UPDATE_NOTICE_OF_DEPARTURE));
        return response;
      })
      .catch(() => {
        dispatch(error(NetworkReducerTypes.UPDATE_NOTICE_OF_DEPARTURE));
      })
      .finally(() => dispatch(hideLoading("modal")));
  };

export const fetchDetailedNoticeOfDeparture = (
  nod_guid
): AppThunk<Promise<AxiosResponse<INoticeOfDeparture>>> => {
  return async (dispatch): Promise<AxiosResponse<INoticeOfDeparture>> => {
    dispatch(request(NetworkReducerTypes.GET_DETAILED_NOTICE_OF_DEPARTURE));
    dispatch(showLoading());
    try {
      try {
        const response: AxiosResponse<INoticeOfDeparture> = await CustomAxios().get(
          `${ENVIRONMENT.apiUrl}${NOTICE_OF_DEPARTURE(nod_guid)}`,
          createRequestHeader()
        );
        dispatch(success(NetworkReducerTypes.GET_DETAILED_NOTICE_OF_DEPARTURE));
        dispatch(storeNoticeOfDeparture(response.data));
        return response;
      } catch {
        dispatch(error(NetworkReducerTypes.GET_DETAILED_NOTICE_OF_DEPARTURE));
      }
    } finally {
      dispatch(hideLoading());
    }
  };
};

export const addDocumentToNoticeOfDeparture = (
  { noticeOfDepartureGuid }: { noticeOfDepartureGuid: string },
  payload: INodDocumentPayload
): AppThunk => (dispatch) => {
  dispatch(showLoading("modal"));
  dispatch(request(NetworkReducerTypes.ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE));
  return CustomAxios()
    .put(
      `${ENVIRONMENT.apiUrl}${NOTICES_OF_DEPARTURE_DOCUMENTS(noticeOfDepartureGuid)}`,
      payload,
      createRequestHeader()
    )
    .then((response) => {
      dispatch(success(NetworkReducerTypes.ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE));
      return response;
    })
    .catch((err) => {
      dispatch(error(NetworkReducerTypes.ADD_DOCUMENT_TO_NOTICE_OF_DEPARTURE));
      return Promise.reject(err);
    })
    .finally(() => dispatch(hideLoading("modal")));
};

export const removeFileFromDocumentManager = ({
  nod_guid,
  document_manager_guid,
}: {
  nod_guid: string;
  document_manager_guid: string;
}) => {
  if (!document_manager_guid) {
    throw new Error("Must provide document_manager_guid");
  }

  return CustomAxios()
    .delete(
      `${ENVIRONMENT.apiUrl + NOTICES_OF_DEPARTURE_DOCUMENT(nod_guid, document_manager_guid)}`,
      createRequestHeader()
    )
    .then((response) => {
      notification.success({
        message: "Successfully deleted document.",
        duration: 10,
      });
      return response;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
