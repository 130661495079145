import React from "react";
import PropTypes from "prop-types";
import { Field } from "@mds/common/components/forms/form";
import { MINE_INCIDENT_DOCUMENTS } from "@mds/common/constants/API";
import FileUpload from "@/components/common/FileUpload";
import { DOCUMENT, EXCEL, SPATIAL, IMAGE, MESSAGE } from "@/constants/fileTypes";

const propTypes = {
  onFileLoad: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  mineGuid: PropTypes.string.isRequired,
  labelIdle: PropTypes.string,
};

const defaultProps = {
  labelIdle: null,
};

const acceptedFileTypesMap = { ...DOCUMENT, ...EXCEL, ...SPATIAL, ...IMAGE, ...MESSAGE };

export const IncidentFileUpload = (props) => (
  <Field
    id="fileUpload"
    name="fileUpload"
    labelIdle={props?.labelIdle}
    component={FileUpload}
    uploadUrl={MINE_INCIDENT_DOCUMENTS(props.mineGuid)}
    acceptedFileTypesMap={acceptedFileTypesMap}
    onFileLoad={props.onFileLoad}
    onRemoveFile={props.onRemoveFile}
    allowRevert
    allowMultiple
  />
);

IncidentFileUpload.propTypes = propTypes;
IncidentFileUpload.defaultProps = defaultProps;

export default IncidentFileUpload;
