import { notification } from "antd";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import { ENVIRONMENT } from "@mds/common/constants/environment";
import {
  ICreatePermitPayload,
  IPermit,
  IPermitAmendment,
  ICreatePermitAmendmentPayload,
  IPermitCondition,
  IUpdatePermitAmendmentPayload,
  IPatchPermitNumber,
  IPatchPermitVCLocked,
  IStandardPermitCondition,
  IPermitConditionCategory,
} from "@mds/common/interfaces";
import { request, success, error, IDispatchError } from "../actions/genericActions";
import { NetworkReducerTypes } from "@mds/common/constants/networkReducerTypes";
import * as permitActions from "../actions/permitActions";
import * as String from "@mds/common/constants/strings";
import * as API from "@mds/common/constants/API";
import { createRequestHeader } from "../utils/RequestHeaders";
import CustomAxios from "../customAxios";
import { AxiosResponse } from "axios";
import { AppThunk } from "@mds/common/interfaces/appThunk.type";

export const createPermit = (
  mineGuid: string,
  payload: ICreatePermitPayload
): AppThunk<Promise<AxiosResponse<IPermit>>> => (dispatch): Promise<AxiosResponse<IPermit>> => {
  dispatch(request(NetworkReducerTypes.CREATE_PERMIT));
  dispatch(showLoading("modal"));
  return CustomAxios()
    .post(ENVIRONMENT.apiUrl + API.PERMITS(mineGuid), payload, createRequestHeader())
    .then((response: AxiosResponse<IPermit>) => {
      notification.success({
        message: "Successfully created a new permit",
        duration: 10,
      });
      dispatch(success(NetworkReducerTypes.CREATE_PERMIT));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.CREATE_PERMIT));
    })
    .finally(() => dispatch(hideLoading("modal")));
};

export const fetchPermits = (mineGuid: string): AppThunk<Promise<void | IDispatchError>> => (
  dispatch
) => {
  dispatch(request(NetworkReducerTypes.GET_PERMITS));
  dispatch(showLoading("modal"));
  return CustomAxios({ errorToastMessage: String.ERROR })
    .get(ENVIRONMENT.apiUrl + API.PERMITS(mineGuid), createRequestHeader())
    .then((response) => {
      dispatch(success(NetworkReducerTypes.GET_PERMITS));
      dispatch(permitActions.storePermits(response.data));
    })
    .catch(() => dispatch(error(NetworkReducerTypes.GET_PERMITS)))
    .finally(() => dispatch(hideLoading("modal")));
};

export const fetchDraftPermitByNOW = (
  mineGuid: string,
  nowApplicationGuid: string
): AppThunk<Promise<AxiosResponse<IPermit[]>>> => (dispatch): Promise<AxiosResponse<IPermit[]>> => {
  dispatch(request(NetworkReducerTypes.GET_PERMITS));
  dispatch(showLoading());
  return CustomAxios({ errorToastMessage: String.ERROR })
    .get(
      ENVIRONMENT.apiUrl + API.DRAFT_PERMITS(mineGuid, nowApplicationGuid),
      createRequestHeader()
    )
    .then((response: AxiosResponse<IPermit[]>) => {
      dispatch(success(NetworkReducerTypes.GET_PERMITS));
      dispatch(permitActions.storeDraftPermits(response.data));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.GET_PERMITS));
    })
    .finally(() => dispatch(hideLoading()));
};

export const updatePermit = (
  mineGuid: string,
  permitGuid: string,
  payload: IPermit
): AppThunk<Promise<AxiosResponse<IPermit>>> => (dispatch): Promise<AxiosResponse<IPermit>> => {
  dispatch(request(NetworkReducerTypes.UPDATE_PERMIT));
  dispatch(showLoading());
  return CustomAxios()
    .put(
      `${ENVIRONMENT.apiUrl}${API.PERMITS(mineGuid)}/${permitGuid}`,
      payload,
      createRequestHeader()
    )
    .then((response: AxiosResponse<IPermit>) => {
      notification.success({
        message: `Successfully updated permit`,
        duration: 10,
      });
      dispatch(success(NetworkReducerTypes.UPDATE_PERMIT));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.UPDATE_PERMIT));
    })
    .finally(() => dispatch(hideLoading()));
};

export const createPermitAmendment = (
  mineGuid: string,
  permitGuid: string,
  payload: Partial<ICreatePermitAmendmentPayload>
): AppThunk<Promise<AxiosResponse<IPermitAmendment>>> => (
  dispatch
): Promise<AxiosResponse<IPermitAmendment>> => {
    dispatch(request(NetworkReducerTypes.CREATE_PERMIT_AMENDMENT));
    dispatch(showLoading("modal"));
    return CustomAxios()
      .post(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENTS(mineGuid, permitGuid)}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitAmendment>) => {
        notification.success({
          message: "Successfully created a new amendment",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.CREATE_PERMIT_AMENDMENT));
        return response;
      })
      .catch(() => {
        dispatch(error(NetworkReducerTypes.CREATE_PERMIT_AMENDMENT));
      })
      .finally(() => dispatch(hideLoading("modal")));
  };

export const createPermitAmendmentVC = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string
): AppThunk<Promise<AxiosResponse<string>>> => (dispatch): Promise<AxiosResponse<string>> => {
  dispatch(request(NetworkReducerTypes.PERMIT_AMENDMENT_VC));
  dispatch(showLoading());
  return CustomAxios()
    .post(
      `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT_VC(mineGuid, permitGuid, permitAmdendmentGuid)}`,
      {},
      createRequestHeader()
    )
    .then((response: AxiosResponse<string>) => {
      notification.success({
        message: `Successfully Issued Permit Verifiable Credentials, Thanks Jason`,
        duration: 10,
      });
      dispatch(success(NetworkReducerTypes.PERMIT_AMENDMENT_VC));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.PERMIT_AMENDMENT_VC));
    })
    .finally(() => dispatch(hideLoading()));
};

export const updatePermitAmendment = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string,
  payload: Partial<IUpdatePermitAmendmentPayload>
): AppThunk<Promise<AxiosResponse<IPermitAmendment>>> => (
  dispatch
): Promise<AxiosResponse<IPermitAmendment>> => {
    dispatch(request(NetworkReducerTypes.UPDATE_PERMIT_AMENDMENT));
    dispatch(showLoading());
    return CustomAxios()
      .put(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT(mineGuid, permitGuid, permitAmdendmentGuid)}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitAmendment>) => {
        const successMessage =
          response.data.permit_amendment_status_code === "DFT"
            ? "Successfully updated draft permit"
            : "Successfully updated permit amendment";
        notification.success({
          message: successMessage,
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.UPDATE_PERMIT_AMENDMENT));
        return response;
      })
      .catch(() => {
        dispatch(error(NetworkReducerTypes.UPDATE_PERMIT_AMENDMENT));
      })
      .finally(() => dispatch(hideLoading()));
  };


export const fetchPermitAmendmentConditionCategories = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string
): AppThunk<Promise<IPermitCondition[] | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition[] | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.GET_PERMIT_CONDITION_CATEGORIES));
    dispatch(showLoading());
    return CustomAxios()
      .get(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT_CONDITION_CATEGORIES(mineGuid, permitGuid, permitAmdendmentGuid)}`,
        createRequestHeader()
      )
      .then((response: AxiosResponse<{ records: IPermitConditionCategory[] }>) => {
        dispatch(permitActions.storeUpdatePermitConditionCategory({
          condition_categories: response.data.records,
          permitGuid
        }));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.GET_PERMIT_CONDITION_CATEGORIES)))
      .finally(() => dispatch(hideLoading()));
  };


export const createPermitAmendmentConditionCategory = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string,
  payload: IPermitConditionCategory
): AppThunk<Promise<IPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.CREATE_PERMIT_CONDITION_CATEGORY));
    dispatch(showLoading());
    return CustomAxios()
      .post(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT_CONDITION_CATEGORIES(mineGuid, permitGuid, permitAmdendmentGuid)}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitConditionCategory>) => {
        notification.success({
          message: "Successfully created permit condition category",
          duration: 10,
        });

        dispatch(success(NetworkReducerTypes.CREATE_PERMIT_CONDITION_CATEGORY));
        dispatch(fetchPermitAmendmentConditionCategories(mineGuid, permitGuid, permitAmdendmentGuid));
        dispatch(fetchPermits(mineGuid));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.CREATE_PERMIT_CONDITION_CATEGORY)))
      .finally(() => dispatch(hideLoading()));
  };

export const updatePermitAmendmentConditionCategory = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string,
  payload: IPermitConditionCategory
): AppThunk<Promise<IPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.UPDATE_PERMIT_CONDITION_CATEGORY));
    dispatch(showLoading());
    return CustomAxios()
      .put(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT_CONDITION_CATEGORIES(mineGuid, permitGuid, permitAmdendmentGuid)}/${payload.condition_category_code}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitConditionCategory>) => {
        notification.success({
          message: "Successfully updated permit condition category",
          duration: 10,
        });

        dispatch(success(NetworkReducerTypes.UPDATE_PERMIT_CONDITION_CATEGORY));
        dispatch(fetchPermitAmendmentConditionCategories(mineGuid, permitGuid, permitAmdendmentGuid));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.UPDATE_PERMIT_CONDITION_CATEGORY)))
      .finally(() => dispatch(hideLoading()));
  };

export const deletePermitAmendmentConditionCategory = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string,
  permitConditionCategoryCode: string,
): AppThunk<Promise<IPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.DELETE_PERMIT_CONDITION_CATEGORY));
    dispatch(showLoading());
    return CustomAxios()
      .delete(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT_CONDITION_CATEGORIES(mineGuid, permitGuid, permitAmdendmentGuid)}/${permitConditionCategoryCode}`,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitConditionCategory>) => {
        notification.success({
          message: "Successfully deleted permit condition category",
          duration: 10,
        });

        dispatch(success(NetworkReducerTypes.DELETE_PERMIT_CONDITION_CATEGORY));
        dispatch(fetchPermitAmendmentConditionCategories(mineGuid, permitGuid, permitAmdendmentGuid));
        dispatch(fetchPermits(mineGuid));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.DELETE_PERMIT_CONDITION_CATEGORY)))
      .finally(() => dispatch(hideLoading()));
  };

export const fetchPermitAmendment = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string
): AppThunk<Promise<IPermitAmendment>> => (dispatch): Promise<IPermitAmendment> => {
  dispatch(request(NetworkReducerTypes.GET_PERMIT_AMENDMENT));
  dispatch(showLoading());
  return CustomAxios()
    .get(
      `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT(mineGuid, permitGuid, permitAmdendmentGuid)}`,
      createRequestHeader()
    )
    .then((response: AxiosResponse<IPermitAmendment>) => {
      dispatch(success(NetworkReducerTypes.GET_PERMIT_AMENDMENT));
      dispatch(permitActions.storePermitAmendment(response.data, permitGuid));
      return response.data;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.GET_PERMIT_AMENDMENT));
    })
    .finally(() => dispatch(hideLoading()));
};

export const removePermitAmendmentDocument = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string,
  documentGuid: string
): AppThunk<Promise<AxiosResponse<string>>> => (dispatch): Promise<AxiosResponse<string>> => {
  dispatch(request(NetworkReducerTypes.UPDATE_PERMIT_AMENDMENT_DOCUMENT));
  dispatch(showLoading());
  return CustomAxios()
    .delete(
      `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT_DOCUMENT(
        mineGuid,
        permitGuid,
        permitAmdendmentGuid,
        documentGuid
      )}`,
      createRequestHeader()
    )
    .then((response: AxiosResponse<string>) => {
      notification.success({
        message: `Successfully removed attached document`,
        duration: 10,
      });
      dispatch(success(NetworkReducerTypes.UPDATE_PERMIT_AMENDMENT_DOCUMENT));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.UPDATE_PERMIT_AMENDMENT_DOCUMENT));
    })
    .finally(() => dispatch(hideLoading()));
};

export const deletePermit = (
  mineGuid: string,
  permitGuid: string
): AppThunk<Promise<AxiosResponse<string>>> => (dispatch): Promise<AxiosResponse<string>> => {
  dispatch(request(NetworkReducerTypes.DELETE_PERMIT));
  dispatch(showLoading());
  return CustomAxios()
    .delete(
      `${ENVIRONMENT.apiUrl}${API.PERMIT_DELETE(mineGuid, permitGuid)}`,
      createRequestHeader()
    )
    .then((response: AxiosResponse<string>) => {
      notification.success({
        message: "Successfully deleted permit and all related permit amendments and documents.",
        duration: 10,
      });
      dispatch(success(NetworkReducerTypes.DELETE_PERMIT));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.DELETE_PERMIT));
    })
    .finally(() => dispatch(hideLoading()));
};

export const deletePermitAmendment = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string
): AppThunk<Promise<AxiosResponse<string>>> => (dispatch): Promise<AxiosResponse<string>> => {
  dispatch(request(NetworkReducerTypes.DELETE_PERMIT_AMENDMENT));
  dispatch(showLoading());
  return CustomAxios()
    .delete(
      `${ENVIRONMENT.apiUrl}${API.PERMIT_AMENDMENT(mineGuid, permitGuid, permitAmdendmentGuid)}`,
      createRequestHeader()
    )
    .then((response: AxiosResponse<string>) => {
      notification.success({
        message: "Successfully deleted permit amendment and all related documents.",
        duration: 10,
      });
      dispatch(success(NetworkReducerTypes.DELETE_PERMIT_AMENDMENT));
      return response;
    })
    .catch(() => {
      dispatch(error(NetworkReducerTypes.DELETE_PERMIT_AMENDMENT));
    })
    .finally(() => dispatch(hideLoading()));
};

export const fetchPermitConditions = (
  mineGuid: string,
  permitGuid: string,
  permitAmdendmentGuid: string
): AppThunk => (dispatch) => {
  dispatch(request(NetworkReducerTypes.GET_PERMIT_CONDITIONS));
  dispatch(showLoading());
  return CustomAxios()
    .get(
      `${ENVIRONMENT.apiUrl}${API.PERMIT_CONDITIONS(mineGuid, permitGuid, permitAmdendmentGuid)}`,
      createRequestHeader()
    )
    .then((response) => {
      dispatch(success(NetworkReducerTypes.GET_PERMIT_CONDITIONS));
      dispatch(permitActions.storePermitConditions(response.data));
    })
    .catch(() => dispatch(error(NetworkReducerTypes.GET_PERMIT_CONDITIONS)))
    .finally(() => dispatch(hideLoading()));
};

export const createPermitCondition = (
  permitAmdendmentGuid: string,
  payload: IPermitCondition
): AppThunk<Promise<IPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.CREATE_PERMIT_CONDITION));
    dispatch(showLoading());
    return CustomAxios()
      .post(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_CONDITIONS(null, null, permitAmdendmentGuid)}`,
        { permit_condition: payload },
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitCondition>) => {
        notification.success({
          message: "Successfully created a new condition",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.CREATE_PERMIT_CONDITION));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.CREATE_PERMIT_CONDITION)))
      .finally(() => dispatch(hideLoading()));
  };

export const deletePermitCondition = (
  permitAmdendmentGuid: string,
  permitConditionGuid: string
): AppThunk<Promise<IPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.DELETE_PERMIT_CONDITION));
    dispatch(showLoading("modal"));
    return CustomAxios()
      .delete(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_CONDITION(
          null,
          null,
          permitAmdendmentGuid,
          permitConditionGuid
        )}`,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitCondition>) => {
        notification.success({
          message: "Successfully deleted permit condition.",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.DELETE_PERMIT_CONDITION));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.DELETE_PERMIT_CONDITION)))
      .finally(() => dispatch(hideLoading("modal")));
  };

export const setEditingConditionFlag = (payload: any): AppThunk => (dispatch) => {
  dispatch(permitActions.storeEditingConditionFlag(payload));
};

export const updatePermitCondition = (
  permitConditionGuid: string,
  permitAmdendmentGuid: string,
  payload: IPermitCondition
): AppThunk<Promise<IPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.UPDATE_PERMIT_CONDITION));
    dispatch(showLoading());
    return CustomAxios()
      .put(
        `${ENVIRONMENT.apiUrl}${API.PERMIT_CONDITION(
          null,
          null,
          permitAmdendmentGuid,
          permitConditionGuid
        )}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPermitCondition>) => {
        notification.success({
          message: `Successfully updated permit condition`,
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.UPDATE_PERMIT_CONDITION));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.UPDATE_PERMIT_CONDITION)))
      .finally(() => dispatch(hideLoading()));
  };

export const patchPermitNumber = (
  permitGuid: string,
  mineGuid: string,
  payload: { now_application_guid: string }
): AppThunk<Promise<IPatchPermitNumber | IDispatchError>> => (
  dispatch
): Promise<IPatchPermitNumber | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.PATCH_PERMIT));
    dispatch(showLoading("modal"));
    return CustomAxios()
      .patch(
        `${ENVIRONMENT.apiUrl}${API.PERMITS(mineGuid)}/${permitGuid}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPatchPermitNumber>) => {
        notification.success({
          message: "Successfully updated permit",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.PATCH_PERMIT));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.PATCH_PERMIT)))
      .finally(() => dispatch(hideLoading("modal")));
  };

export const patchPermitVCLocked = (
  permitGuid: string,
  mineGuid: string,
  payload: { mines_act_permit_vc_locked: boolean }
): AppThunk<Promise<IPatchPermitVCLocked | IDispatchError>> => (
  dispatch
): Promise<IPatchPermitVCLocked | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.PATCH_PERMIT));
    dispatch(showLoading("modal"));
    return CustomAxios()
      .patch(
        `${ENVIRONMENT.apiUrl}${API.PERMITS(mineGuid)}/${permitGuid}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IPatchPermitVCLocked>) => {
        notification.success({
          message: "Successfully updated permit",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.PATCH_PERMIT));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.PATCH_PERMIT)))
      .finally(() => dispatch(hideLoading("modal")));
  };

// standard permit conditions
export const fetchStandardPermitConditions = (noticeOfWorkType: string): AppThunk => (dispatch) => {
  dispatch(request(NetworkReducerTypes.GET_PERMIT_CONDITIONS));
  dispatch(showLoading());
  return CustomAxios()
    .get(
      `${ENVIRONMENT.apiUrl}${API.STANDARD_PERMIT_CONDITIONS(noticeOfWorkType)}`,
      createRequestHeader()
    )
    .then((response) => {
      dispatch(success(NetworkReducerTypes.GET_PERMIT_CONDITIONS));
      dispatch(permitActions.storeStandardPermitConditions(response.data));
    })
    .catch(() => dispatch(error(NetworkReducerTypes.GET_PERMIT_CONDITIONS)))
    .finally(() => dispatch(hideLoading()));
};

export const createStandardPermitCondition = (
  type: string,
  payload: IStandardPermitCondition
): AppThunk<Promise<IStandardPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IStandardPermitCondition | IDispatchError> => {
    const newPayload = {
      ...payload,
      notice_of_work_type: type,
      parent_standard_permit_condition_id: payload.parent_permit_condition_id,
    };
    dispatch(request(NetworkReducerTypes.CREATE_PERMIT_CONDITION));
    dispatch(showLoading());
    return CustomAxios()
      .post(
        `${ENVIRONMENT.apiUrl}${API.STANDARD_PERMIT_CONDITIONS(type)}`,
        { standard_permit_condition: newPayload },
        createRequestHeader()
      )
      .then((response: AxiosResponse<IStandardPermitCondition>) => {
        notification.success({
          message: "Successfully created a new condition",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.CREATE_PERMIT_CONDITION));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.CREATE_PERMIT_CONDITION)))
      .finally(() => dispatch(hideLoading()));
  };

export const deleteStandardPermitCondition = (
  permitConditionGuid: string
): AppThunk<Promise<IStandardPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IStandardPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.DELETE_PERMIT_CONDITION));
    dispatch(showLoading("modal"));
    return CustomAxios()
      .delete(
        `${ENVIRONMENT.apiUrl}${API.STANDARD_PERMIT_CONDITION(permitConditionGuid)}`,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IStandardPermitCondition>) => {
        notification.success({
          message: "Successfully deleted permit condition.",
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.DELETE_PERMIT_CONDITION));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.DELETE_PERMIT_CONDITION)))
      .finally(() => dispatch(hideLoading("modal")));
  };

export const updateStandardPermitCondition = (
  permitConditionGuid: string,
  payload: IStandardPermitCondition
): AppThunk<Promise<IStandardPermitCondition | IDispatchError>> => (
  dispatch
): Promise<IStandardPermitCondition | IDispatchError> => {
    dispatch(request(NetworkReducerTypes.UPDATE_PERMIT_CONDITION));
    dispatch(showLoading());
    return CustomAxios()
      .put(
        `${ENVIRONMENT.apiUrl}${API.STANDARD_PERMIT_CONDITION(permitConditionGuid)}`,
        payload,
        createRequestHeader()
      )
      .then((response: AxiosResponse<IStandardPermitCondition>) => {
        notification.success({
          message: `Successfully updated permit condition`,
          duration: 10,
        });
        dispatch(success(NetworkReducerTypes.UPDATE_PERMIT_CONDITION));
        return response.data;
      })
      .catch(() => dispatch(error(NetworkReducerTypes.UPDATE_PERMIT_CONDITION)))
      .finally(() => dispatch(hideLoading()));
  };
