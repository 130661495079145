import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field } from "@mds/common/components/forms/form";
import { Button, Col, Row } from "antd";
import { compareCodes } from "@common/utils/helpers";
import * as FORM from "@/constants/forms";
import { renderConfig } from "@/components/common/config";
import CustomPropTypes from "@/customPropTypes";
import FormWrapper from "@mds/common/components/forms/FormWrapper";
import RenderResetButton from "@mds/common/components/forms/RenderResetButton";

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  complianceCodes: PropTypes.arrayOf(CustomPropTypes.dropdownListItem).isRequired,
};

export class MineComplianceFilterForm extends Component {
  handleReset = () => {
    this.props.onSubmit();
  };

  render() {
    return (
      <FormWrapper
        name={FORM.MINE_COMPLIANCE_FILTER}
        reduxFormConfig={{
          touchOnBlur: false,
        }}
        onSubmit={this.props.onSubmit} onReset={this.handleReset}>
        <div>
          <Row gutter={6}>
            <Col md={8} xs={24} sm={12}>
              <Field
                id="order_no"
                name="order_no"
                label="Order Number"
                placeholder="Enter order number"
                component={renderConfig.FIELD}
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <Field
                id="report_no"
                name="report_no"
                label="Report Number"
                placeholder="Enter report number"
                component={renderConfig.FIELD}
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <Field
                id="violation"
                name="violation"
                label="Code/Act Violation"
                placeholder="Select code/act violation"
                component={renderConfig.MULTI_SELECT}
                data={
                  this.props.complianceCodes
                    ? this.props.complianceCodes.sort((a, b) => compareCodes(a.value, b.value))
                    : []
                }
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <Field
                id="inspector"
                name="inspector"
                label="Inspector IDIR"
                placeholder="Enter inspector IDIR"
                component={renderConfig.FIELD}
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <Field
                id="order_status"
                name="order_status"
                label="Order Status"
                placeholder="Enter order status"
                component={renderConfig.SELECT}
                data={[
                  { value: "", label: "Overdue, Open, and Closed" },
                  { value: "Overdue", label: "Overdue" },
                  { value: "Open", label: "Open" },
                  { value: "Closed", label: "Closed" },
                ]}
              />
            </Col>
            <Col md={8} xs={24} sm={12}>
              <Field
                id="due_date"
                name="due_date"
                label="Order Due Date"
                placeholder="Select order due date"
                component={renderConfig.DATE}
              />
            </Col>
          </Row>
        </div>
        <div className="right center-mobile">
          <RenderResetButton buttonText="Clear Filters" className="full-mobile" />
          <Button className="full-mobile" type="primary" htmlType="submit">
            Apply Filters
          </Button>
        </div>
      </FormWrapper>
    );
  }
}

MineComplianceFilterForm.propTypes = propTypes;

export default MineComplianceFilterForm;
