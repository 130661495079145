export const VIEW_ALL = "role_view";
export const ADMIN = "role_admin";
export const CONTACT_ADMIN = "role_contact_admin";
export const EDIT_PARTIES = "role_edit_parties";
export const EDIT_MINES = "role_edit_mines";
export const EDIT_PERMITS = "role_edit_permits";
export const EDIT_EXPLOSIVES_PERMITS = "role_edit_explosives_permits";
export const EDIT_TEMPLATE_PERMIT_CONDITIONS = "role_edit_template_conditions";
export const VIEW_ADMIN_ROUTE = "role_view_admin_route";
export const EDIT_REPORTS = "role_edit_reports";
export const EDIT_DO = "role_edit_do";
export const EDIT_VARIANCES = "role_edit_variances";
export const CLOSE_PERMITS = "role_close_permits";
export const DEVELOPER = "role_developer";
export const IN_DEVELOPMENT = "inDevelopment";
export const IN_TESTING = "inTesting";
export const EXECUTIVE = "role_executive_view";
export const EDIT_SECURITIES = "role_edit_securities";
export const EDIT_HISTORICAL_AMENDMENTS = "role_edit_historical_amendments";
export const ADMINISTRATIVE_USERS = "role_mds_administrative_users";
export const EDIT_NOW_DATES = "role_edit_now_dates";
export const EDIT_MINISTRY_CONTACTS = "role_edit_ministry_contacts";
export const EDIT_PROJECT_SUMMARIES = "role_edit_project_summaries";
export const EDIT_INCIDENTS = "role_edit_incidents";
export const EDIT_TSF = "role_edit_tsf";
export const ABANDONED_MINES = "role_abandoned_mines";
export const EDIT_COMPLIANCE_CODES = "role_edit_compliance_codes";
